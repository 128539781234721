import { render, staticRenderFns } from "./UpdateFile.html?vue&type=template&id=54e6d3c1&scoped=true&"
import script from "./UpdateFile.js?vue&type=script&lang=js&"
export * from "./UpdateFile.js?vue&type=script&lang=js&"
import style0 from "./UpdateFile.scss?vue&type=style&index=0&id=54e6d3c1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e6d3c1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
