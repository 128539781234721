import Vue from 'vue';
import { TOAST, TYPE_ACTION_MEDIA_MANAGER } from '@/constants';
import surePromise from "@utils/surePromise";
import requests from "@/helpers/requests";
import STORE from '@/store/types';

export default Vue.extend({
  name: 'UpdateFile',

  data: () => ({
    headquater: {},
    nameImageInput: null,
    descriptionImageInput: null,
    urlImage: null,
    inputCaptureImageStatus: false,
    dataCaptureImage: null,
    statusPanoramic: null,
    buttonStatus: true,
    showRenderImage: false,
    loaderImage: false,
    counter: 0
  }),

  computed: {
    showModalFileUpdate() {
      return this.$store.getters[STORE.GETTERS.ALBUMS.GET_STATUS_SHOW];
    },

    getDataFileUpdate() {
      return this.$store.getters[STORE.GETTERS.ALBUMS.GET_DATA_FILE_UPDATE];
    }
  },

  created() {
    this.headquater = this.getHeadquaterSelectedFromUserProfile();
  },

  updated() {
    if (this.showModalFileUpdate) this.counter++;

    if (this.counter === 1) {
      this.nameImageInput = this.getDataFileUpdate.title;
      this.descriptionImageInput = this.getDataFileUpdate.description;
      this.urlImage = this.getDataFileUpdate.urlImage;
      this.inputValidate();
    }
  },

  methods: {
    inputValidate() {
      this.validateInputFile();

      if (
        (this.nameImageInput !== this.getDataFileUpdate.title) ||
        (this.descriptionImageInput !== this.getDataFileUpdate.description)
      ) {
        this.buttonStatus = false;
      } else {
        this.buttonStatus = true;
      }
    },

    validateInputFile() {
      if (this.nameImageInput !== '') {
        this.inputCaptureImageStatus = false;
      } else {
        this.inputCaptureImageStatus = true;
      }
    },

    async inputValidateCaptureFile(event) {
      let toastStatus = {};
      if (event && event.target.files[0]) {
        this.buttonStatus = true;
        this.loaderImage = true;

        const file = event.target.files[0];
        let statusPanoramic = null;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        /* eslint-disable */
        reader.onload = function (event) {
          const temporaryImage = new Image();
          temporaryImage.src = event.target.result;
          /* eslint-disable */
          temporaryImage.onload = function () {
            if (this.width/2 >= this.height) {
              statusPanoramic = true;
            } else {
              statusPanoramic = false;
            }
          };
        };

        const storageRef = window.firebase.storage().ref(`images/Albums/${this.nameImageInput}`);
        await storageRef.put(file)
        .then(() => {
          const BASE = 'https://firebasestorage.googleapis.com/v0/b/geochef-dev.appspot.com/o/images%2FAlbums';
          this.urlImage = `${BASE}%2F${this.nameImageInput}?alt=media`;

          this.statusPanoramic = statusPanoramic;
          this.loaderImage = false;

          const renderImage = document.getElementById('renderImageId');
          renderImage.src = URL.createObjectURL(file);

          renderImage.style.display = 'block';
          this.showRenderImage = true;
          this.buttonStatus = false;
          toastStatus = { show: true, status: TOAST.SUCCESS, message: 'La imagen cargo exitosamente' }
          this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus)
        })
        .catch(error => {
          console.error('ERROR: ', error);
          toastStatus = { show: true, status: TOAST.ERROR, message: 'Error al intentar subir la imágen' }
          this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus)
        });
      } else {
        toastStatus = { show: true, status: TOAST.ERROR, message: 'Error al seleccionar la imagen' }
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus)
      }
    },

    clearImage() {
      const renderImage = document.getElementById('renderImageId');
      renderImage.style.display = 'none';
      this.showRenderImage = false;
      this.urlImage = this.getDataFileUpdate.urlImage;
      this.inputValidate();
    },

    async updateFileExe() {
      if (this.getDataFileUpdate.typeUpdate === TYPE_ACTION_MEDIA_MANAGER.DELETE_IMAGE) {
        this.updateImageExe();
      } else {
        this.updataAlbumExe();
      }
    },

    async updateImageExe() {
      let dataBody = {};
      if (this.statusPanoramic !== null) {
        dataBody = {
          name: this.nameImageInput,
          description: this.descriptionImageInput,
          url: this.urlImage,
          panoramic: this.statusPanoramic,
          type: 'IMAGE'
        }
      } else {
        dataBody = {
          name: this.nameImageInput,
          description: this.descriptionImageInput
        }
      }

      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, true)
      const { ok } = await surePromise(requests.updateFile(
        this.getDataFileUpdate.id,
        this.headquater.id,
        dataBody
      ));
      let toastStatus = { show: true, status: TOAST.SUCCESS, message: 'La imagen fue actualizada correctamente' };
      if (ok) {
        await this.closeModalFileUpdate();
        await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_ALBUM, this.headquater.id);
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false);
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus);
      } else {
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false);
        toastStatus = { show: true, status: TOAST.ERROR, message: 'Ocurrio un error al intentar actualizar la imagen' }
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus);
      }
    },

    async updataAlbumExe() {
      let dataBody = {};

      dataBody = {
        name: this.nameImageInput,
        description: "Test description for album"
      }
      await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, true);
      const { ok } = await surePromise(requests.updateAlbum(
        this.getDataFileUpdate.id,
        this.headquater.id,
        dataBody
      ));

      let toastStatus = { show: true, status: TOAST.SUCCESS, message: 'Album actualizado correctamente' };
      if (ok) {
        this.closeModalFileUpdate();
        await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_ALBUM, this.headquater.id);
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false);
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus);
      } else {
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.LOADING_STATUS, false);
        toastStatus = { show: true, status: TOAST.ERROR, message: 'Ocurrio un error al intentar actualizar el album' }
        await this.$store.dispatch(STORE.ACTIONS.UTILS_INTERFACE.TOAST_STATUS, toastStatus);
      }
    },

    async closeModalFileUpdate() {
      this.resetDataInputs();
      await this.$store.dispatch(STORE.ACTIONS.ALBUMS.SHOW_FILE_UPDATE, false)
    },

    resetDataInputs() {
      this.counter = 0;
      this.showRenderImage = false;
      this.nameImageInput = null;
      this.descriptionImageInput = null;
      this.urlImage = null;
      this.dataCaptureImage = null;
      this.buttonStatus = true;
    }
  }
});
